import { Box, Container, Stack, Typography, alpha } from "@mui/material";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { AdsComponent } from "../AdsComponent/AdsComponent";
import ParametersTable from "../Parameters Guide/ParametersTable";

export default function CamcorderProfileMain() {
  const rows = [
    { name: 'Audio Bit Rate', description: 'The target audio output bit rate in bits per second' },
    { name: 'Audio Channels', description: 'The number of audio channels used for the audio track' },
    { name: 'Audio Codec', description: 'The audio encoder being used for the audio track' },
    { name: 'Audio Sample Rate', description: 'The audio sampling rate used for the audio track' },
];

    return(
        <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 3, sm: 8 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Camcorder Profile&nbsp;
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' }, fontSize: '16px' }}
          >
            
      Configuring video and audio recording settings can significantly impact the quality and performance of recordings.
      This section provides a detailed overview of available settings, including codecs, bit rates, and file formats.
          </Typography>
        </Stack>
      </Container>

      <Container
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    pt: { xs: 4, sm: 5 },
    pb: { xs: 3, sm: 8 },
    borderBottom: '2px solid',
    borderColor: 'divider',
  }}
>
  <Box sx={{width: '100%', alignSelf: 'flex-start'}}>
            <AdsComponent 
                className='adsbygoogle'
                client={'ca-pub-5244484613216801'}
                slot={'8291271210"'}
                layoutKey="-6t+ed+2i-1n-4w"
                format='fluid'
                style={{display: "block", textAlign: "center"}}/>
    </Box>

  <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
    <Typography
      component="h2"
      variant="h4"
      gutterBottom
      sx={{ color: 'text.primary', textAlign: 'left' }}
    >
      <b>Audio Parameters</b>
    </Typography>
    <Typography textAlign="left" color="text.secondary" sx={{ fontSize: '16px' }}>
      Below are key parameters that define the audio quality and encoding used for recording:
    </Typography>
    <Box component="ul" sx={{ pl: 2, textAlign: 'left', color: 'text.secondary', fontSize: '16px' }}>
      <li><b>Audio Bit Rate:</b> The target audio output bit rate in bits per second.</li>
      <li><b>Audio Channels:</b> The number of audio channels used for the audio track.</li>
      <li><b>Audio Codec:</b> The audio encoder being used for the audio track.</li>
      <li><b>Audio Sample Rate:</b> The audio sampling rate used for the audio track.</li>
    </Box>
  </Stack>
      </Container>

      <Container
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    pt: { xs: 4, sm: 5 },
    pb: { xs: 3, sm: 8 },
    borderBottom: '2px solid',
    borderColor: 'divider',
  }}
>
  <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
    <Typography
      component="h2"
      variant="h4"
      gutterBottom
      sx={{ color: 'text.primary', textAlign: 'left' }}
    >
      <b>File Format Options</b>
    </Typography>
    <Typography textAlign="left" color="text.secondary" sx={{ fontSize: '16px' }}>
      The file format determines how audio and video data are stored and encapsulated. Below are the supported formats:
    </Typography>
    <Box component="ul" sx={{ pl: 2, textAlign: 'left', color: 'text.secondary', fontSize: '16px' }}>
      <li><b>0 - DEFAULT:</b> Automatically selects the most suitable format.</li>
      <li><b>1 - THREE_GPP:</b> 3GPP media file format, commonly used for mobile video recording.</li>
      <li><b>2 - MPEG_4:</b> Standard MP4 format widely supported across devices.</li>
      <li><b>3 - RAW_AMR:</b> AMR NB file format (deprecated in API level 16).</li>
      <li><b>4 - AMR_WB:</b> Adaptive Multi-Rate Wideband audio format.</li>
      <li><b>6 - AAC_ADTS:</b> AAC audio format with ADTS headers.</li>
      <li><b>8 - MPEG_2_TS:</b> H.264/AAC data encapsulated in MPEG2 transport stream.</li>
      <li><b>9 - WEBM:</b> VP8/VORBIS video and audio in a WEBM container.</li>
      <li><b>11 - OGG:</b> Opus audio format encapsulated in an Ogg container.</li>
    </Box>
  </Stack>
      </Container>
      
      <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                pt: { xs: 4, sm: 5 },
                pb: { xs: 3, sm: 8 },
                borderBottom: '2px solid',
                borderColor: 'divider',
              }}
            >
              <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                <Typography
                component="h2"
                variant="h4"
                gutterBottom
                sx={{ color: 'text.primary', textAlign: 'left' }}
              >
                <b>Video Bit Rate</b>
              </Typography>
                <Typography
                  textAlign="left"
                  color="text.secondary"
                  sx={{ alignSelf: 'left', fontSize: '16px' }}
                >
                  The target video output bit rate in bits per second. (This is the target recorded video output bit rate if the application configures the video recording via MediaRecorder Profile without specifying any other MediaRecorder encoding parameters. For example, for high speed quality profiles (from QUALITY_HIGH_SPEED_LOW to QUALITY_HIGH_SPEED_2160P), this is the bit rate where the video is recorded with. If the application intends to record slow motion videos with the high speed quality profiles, it must set a different video bit rate that is corresponding to the desired recording output bit rate (i.e., the encoded video bit rate during normal playback) via MediaRecorder.setVideoEncodingBitRate. For example, if QUALITY_HIGH_SPEED_720P advertises 240fps videoFrameRate and 64Mbps videoBitRate in the high speed CamcorderProfile, and the application intends to record 1/8 factor slow motion recording videos, the application must set 30fps via MediaRecorder.setVideoFrameRate and 8Mbps ( videoBitRate * slow motion factor) via MediaRecorder.setVideoEncodingBitRate. Failing to do so will result in videos with unexpected frame rate and bit rate, or MediaRecorder error if the output bit rate exceeds the encoder limit. If the application intends to do the video recording with MediaCodec encoder, it must set each individual field of MediaFormat similarly according to this CamcorderProfile).                </Typography>
              </Stack>
      </Container>

      <Container
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    pt: { xs: 4, sm: 5 },
    pb: { xs: 3, sm: 8 },
    borderBottom: '2px solid',
    borderColor: 'divider',
  }}
>
  <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
    <Typography
      component="h2"
      variant="h4"
      gutterBottom
      sx={{ color: 'text.primary', textAlign: 'left' }}
    >
      <b>Video Codec Options</b>
    </Typography>
    <Typography textAlign="left" color="text.secondary" sx={{ fontSize: '16px' }}>
      The video codec determines how the video data is compressed and encoded for storage and playback.
      Below are the available codec options:
    </Typography>
    <Box component="ul" sx={{ pl: 2, textAlign: 'left', color: 'text.secondary', fontSize: '16px' }}>
      <li><b>0 - DEFAULT:</b> Automatically selects the most suitable codec.</li>
      <li><b>1 - H263:</b> A legacy codec used mainly for low-bitrate video streaming.</li>
      <li><b>2 - H264:</b> One of the most widely used video codecs, offering high compression and quality balance.</li>
      <li><b>3 - MPEG_4_SP:</b> An older standard used for simple profile MPEG-4 video encoding.</li>
      <li><b>4 - VP8:</b> An open-source video codec designed for web video streaming.</li>
      <li><b>5 - HEVC (H265):</b> A high-efficiency codec that offers superior compression while maintaining high quality.</li>
      <li><b>6 - VP9:</b> A modern, open-source codec optimized for web streaming with improved compression over VP8.</li>
      <li><b>7 - DOLBY VISION:</b> A high-dynamic-range (HDR) video format enhancing color depth and contrast.</li>
      <li><b>8 - AV1:</b> A cutting-edge, royalty-free codec offering superior compression efficiency for modern media applications.</li>
    </Box>
  </Stack>
</Container>

      <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                pt: { xs: 4, sm: 5 },
                pb: { xs: 3, sm: 8 },
                borderBottom: '2px solid',
                borderColor: 'divider',
              }}
            >
              <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                <Typography
                component="h2"
                variant="h4"
                gutterBottom
                sx={{ color: 'text.primary', textAlign: 'left' }}
              >
                <b>Video Frame Rate</b>
              </Typography>
                <Typography
                  textAlign="left"
                  color="text.secondary"
                  sx={{ alignSelf: 'left', fontSize: '16px' }}
                >
The target video frame rate in frames per second. (This is the target recorded video output frame rate per second if the application configures the video recording via MediaRecorder Profile without specifying any other MediaRecorder encoding parameters. For example, for high speed quality profiles (from QUALITY_HIGH_SPEED_LOW to QUALITY_HIGH_SPEED_2160P), this is the frame rate where the video is recorded and played back with. If the application intends to create slow motion use case with the high speed quality profiles, it must set a different video frame rate that is corresponding to the desired output (playback) frame rate via MediaRecorder.setVideoFrameRate. For example, if QUALITY_HIGH_SPEED_720P advertises 240fps videoFrameRate in the CamcorderProfile, and the application intends to create 1/8 factor slow motion recording videos, the application must set 30fps via MediaRecorder.setVideoFrameRate. Failing to do so will result in high speed videos with normal speed playback frame rate (240fps for above example). If the application intends to do the video recording with MediaCodec encoder, it must set each individual field of MediaFormat similarly according to this CamcorderProfile).                  </Typography>
              </Stack>
              
              <Box sx={{width: '100%', alignSelf: 'flex-start'}}>
            <AdsComponent 
                className='adsbygoogle'
                client={'ca-pub-5244484613216801'}
                slot={'8291271210"'}
                layoutKey="-6t+ed+2i-1n-4w"
                format='fluid'
                style={{display: "block", textAlign: "center"}}/>
            </Box>
      </Container>
      <Footer></Footer>
        </Box>
    );
}