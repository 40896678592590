import { Card, CardContent, CircularProgress, Container, Grid, Pagination, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { AdsComponent } from "../AdsComponent/AdsComponent";
import convertDate from "../Helpers/convertDate";
import IVersion from "../Interfaces/IVersion";
import { Link } from "react-router-dom";

export default function VersionsCard() {
    const [versions, setVersions] = useState<IVersion[]>([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const [adPosition, setAdPosition] = useState(window.innerWidth < 600 ? 1 : 3);
    const apiUrl = process.env.REACT_APP_API_URL;
    const itemsPerPage = 18;

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        const fetchVersions = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${apiUrl}/api/Version/GetAll`, {
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        pagging: { page: 1, count: 1000 }
                    })
                });
                const res = await response.json();

                if (res.versions) {
                    const filteredVersions = res.versions.filter((version: any) => version.name && version.name.trim() !== "");
                    const sortedVersions = filteredVersions.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

                    setVersions(sortedVersions);
                }
            } catch (error) {
                console.error("Ошибка при загрузке версий:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchVersions();
        const updateAdPosition = () => {
            setAdPosition(window.innerWidth < 600 ? 1 : 3);
        };

        window.addEventListener("resize", updateAdPosition);
        return () => window.removeEventListener("resize", updateAdPosition);
    }, []);

    if (isLoading) {
        return (
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <CircularProgress />
            </Container>
        );
    }

    const totalPages = Math.ceil(versions.length / itemsPerPage);
    const displayedVersions = versions.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    // Добавляем рекламный блок в конец
    const lastAd = (
        <Grid item xs={12} key={`ads-last`}>
            <AdsComponent
                className="adsbygoogle"
                client="ca-pub-5244484613216801"
                slot="8291271210"
                layoutKey="-6t+ed+2i-1n-4w"
                format="fluid"
                style={{ display: "block" }}
            />
        </Grid>
    );

return (
    <Container>
        <Grid container spacing={3} sx={{ padding: '20px' }}>
            {displayedVersions.map((version, index) => {    

                //рекламный блок после 1 карточки в тф, после 3 на пк
                if (index === adPosition) {
                    return (
                        <Grid item xs={12}  key={`ads-middle`}>
                            <AdsComponent
                                className="adsbygoogle"
                                client="ca-pub-5244484613216801"
                                slot="8291271210"
                                layoutKey="-6t+ed+2i-1n-4w"
                                format="fluid"
                                style={{ display: "block", width: "100%" }}
                            />
                        </Grid>
                    );
                }

                const name = version.name.replace(/\[r\](.*?)\[\/r\]/, '$1'); 
                const isOrange = /\[r\]/.test(version.name); 

                return (
                    <Grid item xs={12} sm={6} md={4} key={version.versionId}>
                        <Link to={`/versions-bsg/${version.versionId}`} style={{ textDecoration: 'none' }}>
                            <Card sx={{ minWidth: 275, cursor: 'pointer' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Version
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{ color: isOrange ? 'orange' : 'inherit' }} 
                                    >
                                        {name}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        for {version.compatibility}
                                    </Typography>
                                    <Typography variant="body2">
                                        Release Date: {convertDate(version.date)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {version.shortInfo}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                );
            })}

            {/* Рекламный блок в конце */}
            {lastAd}
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Pagination
                count={totalPages}
                page={page}
                siblingCount={0}
                onChange={handlePageChange}
            />
        </div>
    </Container>
);

}
