import { alpha, Box, Container, Stack, Typography } from "@mui/material";
import CommonPage from "../Common/CommonPage";
import Footer from "../Footer/Footer";
import CamcorderProfileMain from "./CamcorderProfileMain";


export default function CamcorderProfile() {
    let main;
        main = (
          <CamcorderProfileMain/>
        );
        return(
            <CommonPage main={main}/>
        );
}